/* You can add global styles to this file, and also import other style files */

// Material Theme
@use '@angular/material/theming' as mat;
@use '@angular/material' as *;
@use "scss/crew-time-theme" as ct-theme;
@use 'scss/mixins' as *;

@include mat.all-component-themes(ct-theme.$ct-theme); // Egyelőre nem alkalmazom a témát mert nem minden olvasható vele
// https://material.angular.io/guide/theming


// Kendo
@import "@progress/kendo-theme-material/scss/grid/_index.scss";
@import "~@progress/kendo-theme-default/scss/dialog/_index.scss";
@import "@progress/kendo-theme-material/scss/popover/_index.scss";
@import "~@progress/kendo-theme-default/scss/autocomplete/_index.scss";
@import "~@progress/kendo-theme-default/scss/combobox/_index.scss";
@import "~@progress/kendo-theme-default/scss/dropdownlist/_index.scss";
@import "~@progress/kendo-theme-default/scss/multiselect/_index.scss";
@import "@progress/kendo-theme-material/scss/scheduler/_index.scss";
@import "~@progress/kendo-theme-default/scss/icons/_index.scss";
@import "~@progress/kendo-theme-default/scss/colorpicker/_index.scss";
@import "~@progress/kendo-theme-default/scss/forms/_index.scss";
@import "~@progress/kendo-theme-default/scss/input/_index.scss";
@import "~@progress/kendo-theme-default/scss/maskedtextbox/_index.scss";
@import "~@progress/kendo-theme-default/scss/numerictextbox/_index.scss";
@import "~@progress/kendo-theme-default/scss/dropzone/_index.scss";
@import "~@progress/kendo-theme-default/scss/upload/_index.scss";
//@import "@progress/kendo-theme-material/dist/all.scss";

// Tailwind
//@tailwind base;
//@tailwind components;
//@tailwind utilities;

@import "./scss/variables";
@import "./scss/statuses";
@import "./scss/responsiveness";
//@import "./scss/grid-layout";
@import "./scss/main-container";

// .dense-1 {
//   @include form-field-theme.density(-1);
// }

// .dense-2 {
//   @include form-field-theme.density(-2);
// }

// .dense-3 {
//   @include form-field-theme.density(-3);
// }

html, body { 
//  height: 100%; background-color: grey;
}
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
  overflow: overlay;
}

h1.page-title {
  text-align: center;
  line-height: 2;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  width: 100%;
  background-color: white;
}

.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 50px);
  transform: translateY(-50%);
}

.loader:after {
  content: ' ';
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid #3928d5;
  border-color: #3928d5 transparent #3928d5 transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pop-history, .pop-salaryreason  {
  background-color: #ffffff1f;
  backdrop-filter: blur(6px);

  &.title, &.body {
    padding: 4px;

  }
}

.text-with-maticon {
  padding: 0 0.25rem;
}

.layout-section {
  @apply my-4;
}

.text-center {
  text-align: center;
}

.unimportant {
  color: $unimportant-color;
}

.nowrap {
  white-space: nowrap;
}

.d-block {
  display: block;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.d-flex-col {
  flex-direction: column;
}
.d-flex-align-items-center {
  @apply flex;
  @apply items-center;
}
.d-flex-space-between {
  @apply flex;
  @apply justify-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-content-center {
  justify-content: center;
}
.gap-05 {
  gap: 0.5rem;
}
.gap-1 {
  gap: 1rem;
}
.ml-05 {
  margin-left: 0.5rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.mr-05 {
  margin-right: 0.5rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mb-05 {
  margin-bottom: 0.5rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mt-05 {
  margin-top: 0.5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.px-05 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-05 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.pt-05 {
  padding-top: 0.5rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pb-05 {
  padding-bottom: 0.5rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pl-05 {
  padding-left: 0.5rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pr-05 {
  padding-right: 0.5rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}


.overflow-visible {
  overflow: visible !important;
}

.input-number input {
  @apply text-right;
  position: relative;
  top: 2px;
}


section.save-buttons {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;

  background: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: sticky;
  bottom: 0;
  margin-bottom: 0 !important;
  width: 100%;

  > app-save-button {
    margin: 0 1rem;
  }

  .btn-success {
    background-color: $success-bg-color;
    color: $success-color;
  }

  .btn-warning {
    background-color: $warning-bg-color;
    color: $warning-color;
  }

}

.has-page-level-refrash-button {
  margin-bottom: 10rem !important;
}

.hidden {
  display: none !important;
}

.spinner-inline-block {
  display: inline-block !important;
}

button.button-sm {
  font-size: 16px !important;
  height: 2rem;
  padding: 4px 8px;
  line-height: 1rem;
}

.profile-pic {
  display: inline-block;
  height: 1.3em;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  margin-bottom: -4px;
}

.section-box {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  background: #e1e1e1;
  width: calc(100% - 5rem);
  padding: 1rem;
  border-radius: 0.5rem;
}

.toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;

  .divider {
      margin-left: auto;
  }
}

.grid .row-details {
  @include row-details;

  .detail-box-title {
    color: #777;
    font-weight: 500;
  }
}

.full-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #bcbcbc;
  backdrop-filter: grayscale(0.5);
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

/* Sortable Grid */
.header-align-right .k-cell-inner>.k-link {
  justify-content: flex-end;
}

/* Non-Sortable Grid */
.header-align-right .k-grid th {
  justify-content: right;
}

/* WTS DRAFT */
.table1,
.table2,
.table3,
.table4,
.table5,
.table6,
.table7,
.table8,
.table9 {
  border: 1px solid #ccc;

  td {
    padding: 0 0.5rem;
    border: 0;
  }

  th {
    padding: 0.5rem 0.5rem 0;
    background-color: #eee;
    border: 0;
  }
}

.table3,
.subtable,
.table9 {
  td:last-child {
    text-align: right;
  }
}

.table4,
.table8 {
  >thead>tr {
    display: none;
  }
}

.table2 th:first-child {
  width: 12rem;
}

.table3,
.subtable {
  th:nth-child(2) {
    width: 9rem;
  }
}
/* SCREEN ONLY */
@media screen and (min-width: $mobileWidth) {
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: block;
  }

  .full-height {
    height: calc(100vh - 64px); // a header alatti terület
  }
}

/* MOBILE ONLY */
.mobile-only {
  display: none;
}
@media screen and (max-width: $mobileWidth) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
}

/* For touch-only devices */
@media (hover:none), (pointer:coarse){   
  .show-on-touchonly {
    display:block !important;
  }
  .hide-on-touchonly {
    display:none !important
  }
}

// KENDO OVERRIDE

kendo-formfield.form-field-upload kendo-floatinglabel label {
  padding: 0 5px;
  background: #fafafa !important;
  margin: 0 5px;
}

/** Edited grid cell */
.k-grid .k-edit-cell,
.k-grid .k-command-cell,
.k-grid .k-grid-edit-row td {
  padding: 6px;
}

/** Kendo Grid szűrő */

kendo-grid {
  .k-filter-row td,
  .k-filter-row th {
    padding-right: 4px;
    padding-left: 4px;
  }

  .k-filtercell .k-input {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media screen and (max-width: 1600px) {
kendo-grid {
    .k-cell-inner>.k-link {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
    .k-filter-row td,
    .k-filter-row th {
      padding-top: 4px;
      padding-bottom: 4px;
    }
}
}